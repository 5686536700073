@import './../../../../../node_modules/@i-wyze/ui-shared-library/src/lib/theme/styles.scss';

* {
	font-family: 'Montserrat', sans-serif;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

ul {
	list-style: disc;
}

li {
	height: min-content;
}

ol {
	list-style: decimal;
}

body {
	@include font-size($body-font-sizes);
	color: $color-neutral-900;
	overflow-x: hidden;
	background-color: $color-neutral-100;
}

// TODO: move utilities to ui-library

.d-flex {
	display: flex;
}

.d-none {
	display: none;
}

.d-sticky {
	position: fixed;
	top: 0;
	z-index: 1;
}

.d-flex-wrap {
	@extend .d-flex;
	flex-wrap: wrap;
}

b {
	font-weight: 500;
}

.cdk-overlay-container {
	position: fixed;
	top: 10%;
	left: 40%;
}
